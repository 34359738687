import React, { useEffect, useState } from 'react';
import { Box, Container, Autocomplete, TextField, FormGroup } from '@mui/material';
import Header from 'components/Headers/Header';
import CommonTable from 'components/Tables/CommonTable';
import componentStyles from "assets/theme/views/admin/tables.js";
import { makeStyles } from '@mui/styles';
import { dataService } from 'services/data-service';
import OrdersCommentsDialog from 'components/dialoges/OrdersCommentsDialog';

const useStyles = makeStyles(componentStyles);

const OrderCommentsList = () => {
    const classes = useStyles();

  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [comments, setComments] = useState([]);
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingRows, setLoadingRows] = useState({});
  const [viewDetailDialog, setViewDetailDialog] = useState({
    isVisible: false,
    data: null,
    extraData: null
  });
  const [isReload, setIsReload] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      const userList = await dataService('get', 'CommonList', `Query=UsersWithLoginAccess`);
      if (userList.status === 200) {
        setUserList(userList?.data || [])
    }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchComments = async () => {
        let res = await dataService('get', `orders/comments/${selectedUser || 0}`, `page=${page}&offset=${offset}`);
        setComments(res?.data);
        setTotalRecord(res?.totalRecord);
        setIsLoading(false);
      }

    fetchComments();
  }, [selectedUser, page, offset]);

  const columns = [
    {
      id: 'OrderNumber', numeric: false, disablePadding: true, label: 'OrderNumber', dataType: 'component',
      Component: ({ row }) => {
        return (
          <Box>
            #{row.OrderNumber}
          </Box>
        )
      }
    },
    { id: 'ProductName', numeric: false, disablePadding: false, label: 'Product Name', dataType: 'string' },
    {
      id: 'FileName', numeric: false, disablePadding: false, label: 'File Name', dataType: 'component',
      Component: ({ row }) => {
        return (
          <Box
            sx={{
              maxWidth: "400px",
              minWidth: "200px",
              overflowWrap: "break-word",
              wordBreak: "break-word",
              whiteSpace: "normal",
            }}
          >
            {row.FileName}
          </Box>
        )
      }
    },
    { id: 'FileSize', numeric: false, disablePadding: false, label: 'File Size', dataType: 'string' },
    { id: 'CustomerName', numeric: false, disablePadding: false, label: 'Customer Name', dataType: 'string' },
    { id: 'OrderDate', numeric: false, disablePadding: false, label: 'Order Date', dataType: 'date' },
    { id: 'Quantity', numeric: false, disablePadding: false, label: 'Quantity', dataType: 'string' },
  ];

  const onSigleClick = async (row) => {
    setLoadingRows((prevRows) => ({
      ...prevRows,
      [row.OrderId]: true
    }));
    try {
      let res = await dataService('get', `OrdersCommentsList/${row?.OrderId}`, '');
      if (res.status === 200 && res?.data) {
        setViewDetailDialog({
          isVisible: true,
          data: res?.data,
          extraData: { CustomerName: row?.CustomerName, FileName: row?.FileName, OrderNumber: row?.OrderNumber, OrderId: row?.OrderId }
        });
      }
      setLoadingRows((prevRows) => ({
        ...prevRows,
        [row.OrderId]: false
      }));
    } catch (error) {
      setLoadingRows((prevRows) => ({
        ...prevRows,
        [row.OrderId]: false
      }));
    }
  }

    return (
        <>
    
          <Header />
          <Container
            maxWidth={false}
            component={Box}
            marginTop="-6rem"
            classes={{ root: classes.containerRoot }}
          >
            <CommonTable
              title="Order Comments List"
              columns={columns}
              rows={comments}
              page={page - 1}
              total={totalRecord}
              pagedata={setPage}
              actions={[]}
              setOffset={setOffset}
              offset={offset}
              loading={isLoading}
              loadingRows={loadingRows}
              uniqueKey="OrderId"
              isLoading={isLoading}
              onSigleClick={onSigleClick}
              isSearch={false}
              component={
                <FormGroup>
                  <Autocomplete
                    size='small'
                    id='user-select'
                    options={userList}
                    getOptionLabel={(option) => option.Name}
                    value={userList.find(user => user.UserId === selectedUser)}
                    autoHighlight
                    onChange={(e, newValue) => {
                        if(newValue) {
                            setSelectedUser(newValue.UserId)
                        } else {
                            setSelectedUser(0)
                        }
                    }}
                    renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select User" />}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                          height: '35px',
                        },
                        '& .MuiAutocomplete-input': {
                          borderRight: '0px',
                          fontSize :  "12px" 
                        },
                      }}

                  />
                </FormGroup>
              }
            />
          </Container>
          {viewDetailDialog?.isVisible &&
        <OrdersCommentsDialog
          isOrderFlow={false}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setIsReload={setIsReload}
          isReload={isReload}
          open={viewDetailDialog}
          setOpen={setViewDetailDialog}
          onClose={() => {
            setViewDetailDialog({ data: null, isVisible: false, extraData: null })
          }} />}
        </>
    );
};

export default OrderCommentsList;
